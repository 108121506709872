import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
//GATSBY PAGE SEO + LAYOUT
import SEO from "../../components/seo.js"
import Layout from "../../components/layout.js"

// PAGE COMPONENTS AND TEMPLATES.
import { InPageNav } from "../../components/navbar.js"
import { McatPricingV2 } from "../../components/sections/pricingSection.js"

const PricingData = {
  title: "LSAT",
  description: "For a two month course.",
  price: {
    full: "1500",
    installments: "500",
  },
}
const TabWrap = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(0, 0, 0, 0.01);
  width: 100%;
  justify-content: space-around;
  position: sticky;
  top: 50px;
  min-height: 3em;
  background-color: ${props =>
    !!props.theme && props.theme === "dark" ? "transparent" : "transparent"};
  color: ${props =>
    !!props.theme && props.theme === "dark" ? "white" : "black"};
  // background-color: #fff;
  backdrop-filter: saturate(180%) blur(10px);
  z-index: 998;
`
const Tab = styled.div`
  height: 100%;
  box-sizing: border-box;
  color: ${props => (props.active ? "#f50057" : "black")};
  cursor: pointer;
`

const LSATPricing = ({ location }) => {
  const [currTab, setTab] = useState(0)
  const RedirectToPage = dest => {
    navigate(dest)
  }
  return (
    <Layout navColor="transparent">
      <SEO title="99point9 LSAT Pricing" />
      <InPageNav
        pageTitle="LSAT Pricing"
        currentPath={location.pathname}
        navItems={[
          { link: "/lsat", label: "Overview" },
          { link: "/lsat/how_it_works", label: "How It Works" },
          { link: "/lsat/schedule", label: "Class Schedule" },
          { link: "/last/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatPricingV2
        backgroundColor={"rgb(247,247,247)"}
        data={PricingData}
        dest="/lsat/schedule/"
      />
      <TabWrap>
        <Tab onClick={() => setTab(0)} active={currTab === 0}>
          <h3>Comparison</h3>
        </Tab>
        <Tab onClick={() => setTab(1)} active={currTab === 1}>
          <h3>FAQs</h3>
        </Tab>
      </TabWrap>
      <h4 style={{ margin: "116px auto", textAlign: "center" }}>
        We're currently updating our site: These sections are coming soon.{" "}
      </h4>
    </Layout>
  )
}
export default LSATPricing
